function toggleMobileMenu(event){
  event.preventDefault();
  this.closest('.js-container').classList.toggle("show");
  console.log(this.closest('.js-container'))
}

function addEventListenerByClass(className, event, fn) {
  const list = document.querySelectorAll(className);
  list.forEach(function(item) {
    item.addEventListener(event, fn, false);
  });
}

window.addEventListener("load", function() {
  addEventListenerByClass('.js-nav-button', 'click', toggleMobileMenu);
  console.log('load')
})