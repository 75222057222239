window.onload = async function() {
  const container = document.querySelector('.js-contacts');
  const response = await fetch('/api/contacts');
  const responseJSON = await response.json();

  if (container !== null && responseJSON.constructor === ({}).constructor) {
    container.querySelector('.js-spinner').classList.add('visually-hidden');
    container.querySelector('.js-email').innerHTML = '<a href=mailto:'+responseJSON.email+'>'+responseJSON.email+'</a>';
    container.querySelector('.js-phone').innerHTML = '<a href=tel:'+responseJSON.phone+'>'+responseJSON.phone+'</a>';
    container.querySelector('.js-telegram').innerHTML = '<a href=tg://resolve?domain='+responseJSON.telegram+'>'+responseJSON.telegram+'</a>';
  }
}
